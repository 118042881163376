import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Store} from '@ngxs/store';

@Injectable()

export class BrandInterceptor  implements HttpInterceptor {

    constructor(private store: Store) {}
    intercept( req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const brand = this.store.snapshot().auth.selectedBrand;

        if (brand) {
            const brandId = String(brand);
            const reqCopy = req.clone({ headers: req.headers.set('Brand', brandId)});
            return next.handle(reqCopy);
        } else {
            return next.handle(req);
        }
    }
}
