import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {SaveFilter} from "./main.actions";

export interface MainStateModel {
  filters: any;
}

@Injectable()

@State<MainStateModel>({
  name: 'main',
  defaults: {
    filters: null
  }
})

export class MainState {

  constructor() {}

  @Selector()
  static getFilters(state: MainStateModel): any {
    return state.filters || null;
  }


  @Action(SaveFilter)
  saveFilter(ctx: StateContext<MainStateModel>, {data}: SaveFilter): void {
    console.log('data for login action', data)
    // ctx.patchState({
    //   token: data.login.token,
    //   refreshToken: data.login.refreshToken,
    //   user: data.login.user
    // });
  }

}
