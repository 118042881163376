import {Action, Selector, State, StateContext} from '@ngxs/store';

import {Injectable} from '@angular/core';
import {Login, Logout, SelectBrand} from "./auth.actions";

export interface AuthStateModel {
  token: any;
  refreshToken: any;
  selectedBrand: any;
  selectedBrandName: any;
  user: any;
  roles: any[];
}

@Injectable()

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    token: null,
    refreshToken: null,
    selectedBrand: null,
    selectedBrandName: null,
    user: null,
    roles: [],
  }
})

export class AuthState {

  constructor() {}

  @Selector()
  static getToken(state: AuthStateModel): any {
    return state.token || false;
  }

  @Selector()
  static getRefreshToken(state: AuthStateModel): any {
    return state.refreshToken || null;
  }

  @Selector()
  static getSelectedBrand(state: AuthStateModel): any {
    return state.selectedBrand || 0;
  }

  @Selector()
  static getSelectedBrandName(state: AuthStateModel): any {
    return state.selectedBrandName || 'B2Tech';
  }

  @Selector()
  static getUser(state: AuthStateModel): any {
    return state.user || null;
  }

  @Action(Login)
  login(ctx: StateContext<AuthStateModel>, {data}: Login): void {
    console.log('data for login action', data)
    ctx.patchState({
      token: data.token,
      refreshToken: data.refreshToken,
      user: data.user,
      roles: data.user.roles,
    });
  }


  @Action(Logout)
  logout(ctx: StateContext<AuthStateModel>, action: Logout): void {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      token: null,
      refreshToken: null,
      user: null,
      roles: [],
    });
  }


  @Action(SelectBrand)
  selectBrand(ctx: StateContext<AuthStateModel>, {brandId, brandName}: SelectBrand): void {
    const state = ctx.getState();
    console.log(brandId)
    ctx.setState({
      ...state,
      selectedBrand: brandId,
      selectedBrandName: brandName,
    });
  }

}
