import { NgModule } from '@angular/core';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserModule} from "@angular/platform-browser";
import {FormsModule} from "@angular/forms";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {NgxsModule} from "@ngxs/store";
import {NgxsReduxDevtoolsPluginModule} from "@ngxs/devtools-plugin";
import {AuthState} from "./store/auth/auth.service";
import {NgxsStoragePluginModule} from "@ngxs/storage-plugin";
import {MainState} from "./store/main/main.service";
import {AuthInterceptor, BrandInterceptor} from "./core/intercepters";
import {ResponseInterceptor} from "./core/intercepters";
import {RefreshJwtInterceptor} from "./core/intercepters/refresh.interceptor";

@NgModule({
    providers: [
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: RefreshJwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: BrandInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true }
    ],
    declarations: [
        AppComponent
    ],
    imports: [
        AppRoutingModule,
        BrowserModule,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        NgxsModule.forRoot([
            AuthState,
            MainState
        ], {
            developmentMode: false
        }),
        NgxsStoragePluginModule.forRoot({
            key: ['auth']
        }),
        NgxsReduxDevtoolsPluginModule.forRoot()
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
