import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Observable} from "rxjs";
import {LoginInterface, LoginResponseInterface} from "../../interfaces";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  login(data: LoginInterface): Observable<LoginResponseInterface> {
      return this.http.post<LoginResponseInterface>( environment.url + `/api/Auth/login`, data);
  }

  loginAs(data: LoginInterface): Observable<LoginResponseInterface> {
      return this.http.post<LoginResponseInterface>( environment.url + `/api/User/BrandLogin`, data);
  }
  //
  // loginAs(data: LoginInterface): Observable<LoginResponseInterface> {
  //     return this.http.get<LoginResponseInterface>( environment.url + `/api/User/BrandLogin`, {params: {...data}});
  // }

  refreshToken(data: any): Observable<LoginResponseInterface> {
      return this.http.post<LoginResponseInterface>( environment.url + `/api/Auth/refresh`, data);
  }
}
