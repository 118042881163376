import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import {BRANDS_ROUTES} from "./pages/login/logins.routes";

const routerOptions: ExtraOptions = {
    anchorScrolling: 'enabled'
};

const routes: Routes = [
    {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'
    },
    {
        path: 'total-report',
        loadChildren: () => import('./pages/total-report/total-report.routes').then((mod) => mod.TOTAL_REPORT_ROUTES)
    },
    {
        path: 'brands',
        loadChildren: () => import('./pages/login/logins.routes').then((mod) => mod.BRANDS_ROUTES)
    },
    {
        path: 'any-other',
        loadChildren: () => import('./pages/any-other/any-other.routes').then((mod) => mod.ANY_OTHER_ROUTES)
    },
    {
        path: 'authorities',
        loadChildren: () => import('./pages/authorities/authorities.routes').then((mod) => mod.AUTHORITIES_ROUTES)
    },
    {
        path: 'double-confirmation',
        loadChildren: () => import('./pages/double-confirmation/double-confirmation.routes').then((mod) => mod.DOUBLE_CONFIRMATION_ROUTES)
    },
    {
        path: 'online',
        loadChildren: () => import('./pages/online/online.routes').then((mod) => mod.ONLINE_ROUTES)
    },
    {
        path: 'retail',
        loadChildren: () => import('./pages/retail/retail.routes').then((mod) => mod.RETAIL_ROUTES)
    },
    {
        path: 'save-search',
        loadChildren: () => import('./pages/save-search/save-search.routes').then((mod) => mod.SAVE_SEARCH_ROUTES)
    },
    {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.routes').then((mod) => mod.DASHBOARD_ROUTES)
    },
    {
        path: 'settings',
        loadChildren: () => import('./pages/settings/settings.routes').then((mod) => mod.SETTINGS_ROUTES)
    },
    {
        path: 'tax-reporting',
        loadChildren: () => import('./pages/tax-reporting/tax-reporting.routes').then((mod) => mod.TAX_REPORTING_ROUTES)
    },
    {
        path: 'login',
        loadComponent: () => import('./pages/auth/login/login.component').then((module) => module.LoginComponent)
    },

    { path: '**', redirectTo: '/all' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, routerOptions)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
