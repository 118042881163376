import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient) {}


  getCustomersMedium() {
      return this.http
          .get<any>('assets/demo/data/customers-medium.json')
          .toPromise()
          .then((res) => res.data as any[])
          .then((data) => data);
  }

  getCustomersLarge() {
      return this.http
          .get<any>('assets/demo/data/report-data.json')
          .toPromise()
          .then((res) => res.data as any[])
          .then((data) => data);
  }

  getReports() {
      return this.http
          .get<any>('assets/demo/data/save.json')
          .toPromise()
          .then((res) => res.data as any[])
          .then((data) => data);
  }
}
